<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="mt-16" cols="11" sm="6">
        <h1 class="text-center">Two-Factor Authentication</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="text-left" cols="11" sm="6">
        <p>{{ $t('login-pin.text') }}</p>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="11" sm="6" md="6" lg="4" xl="3">
        <h2>Enter code:</h2>
      </v-col>
    </v-row>
    <v-form @submit.prevent="onSubmit" ref="form">
      <v-row class="justify-center">
        <v-col class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-3 pin-codes">
          <v-otp-input
            v-model="otp"
            length="6"
            height="60px"
            type="number"
            ref="otp"
            @finish="onFinish"
            ></v-otp-input>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="text-right" cols="11" sm="6" md="6" lg="4" xl="3">
          <v-btn id="login-pin-btn" ref="pin7" type="submit">Log in</v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="mt-16 text-left small-text" cols="11" sm="6" md="6" lg="4" xl="3">
          Dou you need help? Write us
          <a href="mailto:support@vision-cockpit.com">support@vision-cockpit.com</a>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

export default {
  name: 'LoginPinPage',
  data: () => ({
    logintoken: '',
    otp: null,
  }),
  created() {
    this.logintoken = this.$store.getters['user/logintoken'];
    if (!this.logintoken.length > 0) {
      this.$router.push('/logout');
    }
  },
  computed: {
    navigationKey: {
      get() { return this.$store.getters['navigation/key']; },
    },
  },
  mounted() {
    this.$nextTick(() => {
      const firstInput = document.getElementsByTagName('input')[0];
      if (firstInput) {
        firstInput.focus();
      }
    });
  },
  methods: {
    onFinish() {
      const btn = document.getElementById('login-pin-btn');
      if (btn) {
        btn.focus();
      }
    },
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch('dialogloader/show', 'Login ...');
      this.$store.dispatch('user/loginPin', { logintoken: this.logintoken, pin: this.otp })
        .then(() => {
          this.$store.dispatch('navigation/refresh', this.navigationKey + 1);
          const nextUrl = this.$store.getters['returnUrl/nextUrl'];
          const userCompanies = this.$store.getters['user/companies'];
          const currentUserIsAdmin = this.$store.getters['user/isAdmin'];
          if (nextUrl) {
            this.$store.dispatch('returnUrl/setNextUrl', null);
            this.$router.push(nextUrl);
          } else if (userCompanies.length > 1 || currentUserIsAdmin) {
            this.$router.push('/customeruser');
          } else {
            this.$store.dispatch('user/companyUser', userCompanies[0].company.id)
              .then(() => {
                this.$router.push('/dashboard');
              });
          }
        })
        .catch((error) => {
          if (error instanceof TypeError) {
            this.$store.dispatch('notificationbar/showNotification', {
              msg: 'Network Error: Server is not responding.',
              color: 'error',
              show: true,
            });
          }
          this.$router.push('/login');
        })
        .finally(() => {
          this.$store.dispatch('dialogloader/hide');
        });
    },
  },
};
</script>
<style lang="scss">

</style>
