<template>
  <v-container fluid >
    <v-row class="align-start fill-height">
      <v-col cols="12" md="5" class="login-wrapper align-start order-2 order-md-1 mt-md-16">
        <v-row class="justify-center">
          <v-col class="mt-5 mt-md-16" cols="10" sm="6">
            <h1>Login</h1>
          </v-col>
        </v-row>
        <v-form @submit.prevent="onSubmit" ref="form">
          <v-row class="justify-center">
            <v-col cols="10" sm="6">
              <v-text-field
                label="E-mail"
                v-model="username"
                :rules="$rules.required && $rules.email"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                label='Password'
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                :rules="$rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" sm="6">
              <v-btn type="submit" class="btn-login">Log In</v-btn>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col class="text-left forgot-password pl-3 pt-0" cols="10" sm="6">
              <router-link to="/forgot-password">Forgot password?</router-link>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col class="text-left" cols="10" sm="4">
              <p>{{ $t('login.text') }}</p>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="footer-row justify-center pt-md-10">
          <LoginFooter />
        </v-row>
      </v-col>
      <v-col cols="12" md="7"
        class="read-more-wrapper d-flex justify-center align-start
        fill-height order-1 order-md-2 pt-16 pt-md-5 pb-8 pb-md-0 mt-md-5"
      >
        <v-row class="justify-start align-center ml-md-16 mt-md-11">
          <v-col>
            <v-row>
              <v-col cols="5" md="12" class="text-center text-md-start pb-0">
                <img alt="Vision Tools" class="login-ai-image"
                     src="@/assets/images/ai-training-red_128px.svg">
              </v-col>
              <v-col cols="7" md="12">
                <h1 class="read-more-text">
                  Start training AI-models <br>
                  for Deep Learning<br>
                  Machine Vision Systems
                </h1>
              </v-col>
              <v-col cols="5" md="12" class="py-0">
              </v-col>
              <v-col cols="7" md="12">
                <v-btn type="button" class="btn-login" target="_blank"
                       :href=readmoreLink
                >
                  Read more
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';
import LoginFooter from '@/views/layout/LoginFooter.vue';

export default {
  name: 'LoginPage',
  components: { LoginFooter },
  data: () => ({
    username: '',
    password: '',
    showPassword: false,
    logintoken: null,
    readmoreLink: 'https://www.vision-tools.com/produkte/ki-werkzeuge',
  }),
  created() {
    if (!checkTokenExpiriesDate()) {
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$store.dispatch('navigation/refresh', this.navigationKey + 1);
        });
    }
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store.dispatch('dialogloader/show', 'Login ...');

      this.$store.dispatch('user/login', { username: this.username, password: this.password })
        .then(() => {
          if (this.$store.getters['user/isFullyRegistered']) {
            this.$router.push('/loginpin');
          } else {
            this.$router.push('/loginfirsttime');
          }
        })
        .catch((error) => {
          if (error instanceof TypeError) {
            this.$store.dispatch('notificationbar/showNotification', {
              msg: 'Network Error: Server is not responding.',
              color: 'error',
              show: true,
            });
          }
        })
        .finally(() => {
          this.$store.dispatch('dialogloader/hide');
        });
    },
  },
};
</script>

<style lang="scss">

</style>
