<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="mt-16" cols="10" sm="6">
        <h1>Forgot password?</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="mt-16 text-left small-text" cols="10" sm="6">
        Forgot your account? Write us an e-mail or call us +49 7254 9351390
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'UserForgotPasswordPage',
  data() {
    return {
      token: null,
    };
  },
  created() {
    this.token = this.$route.params.token;
  },
};
</script>
