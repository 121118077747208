<template>
  <v-footer
    color="#f5f5f5"
    padless
    bottom
    class="footer pb-3 login-footer"
  >
    <v-container>
      <v-row class="justify-center">
        <p class="text-center mb-2 mb-md-12">
          Are you interested in our Cloud Service and want to sign up?<br>
          Get more information from
          <a rel="noreferrer noopener" target="_blank"
            :href=footerLinks[0].link
          >
            {{ footerLinks[0].linkText }}
          </a>
        </p>
      </v-row>
    <v-row
      class="justify-center"
      no-gutters
    >
      <div
        v-for="link in legalLinks"
        :key="link.link"
        class="footer__link-wrap my-2 pr-3 pl-3"
      >
        <a :href="link.link" class="footer__link" rel="noreferrer noopener"
           target='_blank'>
          {{ link.linkText }}
        </a>
      </div>
      <div class="footer__link-wrap my-2 pr-3 pl-3"
           id="version"
           style="font-size:12px;padding-top:5px;"
      >
        {{ version }}
      </div>
    </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import checkTokenExpiriesDate from '../../helper/checkTokenExpiriesDate';
import version from '../../../version.json';

export default {
  data: () => ({
    footerLinks: [
      {
        linkText: 'support@vision-cockpit.com',
        link: 'mailto:support@vision-cockpit.com',
        external: true,
        iconClass: 'mdi mdi-email-outline',
      },
    ],
    legalLinks: [
      {
        linkText: 'Legal notice',
        link: 'https://www.vision-tools.com/en/legal-notice.html',
        external: true,
      },
      {
        linkText: 'Privacy Policy',
        link: 'https://www.vision-tools.com/en/privacy-policy.html',
        external: true,
      },
    ],
    version: version.version,
  }),
  computed: {
    userLogged: {
      get() {
        return checkTokenExpiriesDate();
      },
    },
  },
};
</script>

<style lang="scss">
.footer {
  .v-btn {
    background-color: transparent;
  }
  .footer__links .footer__link {
    background-color: transparent;
    font-size: 16px;
  }
}
</style>
