<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="mt-16" cols="10" sm="6">
        <h2>First login</h2>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="10" sm="6">
        <h3>Enter new password:</h3>
        <p>
          Please enter 8-32 characters with at least one number, one capital letter, one
          lowercase letter and one of the following characters: !@$%^&#x26;*()&#x3C;&#x3E;?/[]-=+
        </p>
      </v-col>
    </v-row>
    <v-form @submit.prevent="onSubmit" ref="form">
      <v-row class="justify-center">
        <v-col cols="10" sm="6">
          <v-text-field
            label="Password"
            v-model="passwordNew"
            :append-icon="showPasswordNew ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPasswordNew ? 'text' : 'password'"
            @click:append="showPasswordNew = !showPasswordNew"
            :rules="[rules.minLength(8), rules.maxLength(32)]"
            class="mb-4"
          ></v-text-field>
          <v-text-field
            label="Repeat password"
            v-model="passwordRepeat"
            :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPasswordRepeat ? 'text' : 'password'"
            @click:append="showPasswordRepeat = !showPasswordRepeat"
            :rules="$rules.required && passwordConfirmationRule"
          ></v-text-field>
          <form-error :errors="formErrors.password_repeat"/>
          <form-error :errors="formErrors.password_new"/>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="mt-6" cols="10" sm="6">
          <h3>Two Factor Authentication:</h3>
          <p>Scan this QR code in a supported OTP app (e.g.
            Google Authenticator or Windows Authenticator)
            and enter the generated 6-digit PIN. Then enter
            your new login password twice.</p>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="10" sm="6">
          <v-row class="qr-code">
            <v-img :src="qrCode"/>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="10" sm="6">
          <h3>Enter PIN:</h3>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="10" sm="6">
          <v-row class="pin-codes">
            <v-text-field
              class="pin"
              v-model="pin1"
              ref="pin1"
              maxlength="1"
              autofocus
              @input="onInput($event,1)"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              class="pin"
              v-model="pin2"
              ref="pin2"
              maxlength="1"
              @input="onInput($event,2)"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              class="pin"
              v-model="pin3"
              ref="pin3"
              maxlength="1"
              @input="onInput($event,3)"
              autocomplete="off"
            ></v-text-field>
            <div class="code-dot">
              <span class="dot"></span>
            </div>
            <v-text-field
              class="pin"
              v-model="pin4"
              ref="pin4"
              maxlength="1"
              @input="onInput($event,4)"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              class="pin"
              v-model="pin5"
              ref="pin5"
              maxlength="1"
              @input="onInput($event,5)"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              class="pin"
              v-model="pin6"
              ref="pin6"
              maxlength="1"
              @input="onInput($event,6)"
              autocomplete="off"
            ></v-text-field>
          </v-row>
          <form-error :errors="formErrors.pin"/>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="text-right" cols="10" sm="6">
          <v-btn ref="pin7" type="submit">Log in</v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="mt-16 text-left contact" cols="10" sm="6">
          Do you need help? Write us
          <a href="mailto:support@vision-cockpit.com">support@vision-cockpit.com</a>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import FormError from '@/components/_common/FormError.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';

export default {
  name: 'LoginFirstTimePage',
  components: {
    FormError,
  },
  mixins: [
    FormErrorMixin,
    FormValidationRulesMixin,
  ],
  data: () => ({
    passwordOld: '',
    passwordNew: '',
    passwordRepeat: '',
    showPasswordNew: false,
    showPasswordRepeat: false,
    passwordComparision: [
      (v) => this.passwordNew === v || 'The passwords provided differ.',
    ],
    logintoken: '',
    qrCode: '',
    pin1: null,
    pin2: null,
    pin3: null,
    pin4: null,
    pin5: null,
    pin6: null,
  }),
  created() {
    if (checkTokenExpiriesDate()) {
      this.$router.push('/customeruser');
    } else {
      this.passwordOld = this.$store.getters['user/firstPassword'];
      this.logintoken = this.$store.getters['user/logintoken'];
      this.qrCode = this.$store.getters['user/qrCode'];
      if (!this.logintoken.length > 0) {
        this.$router.push('/logout');
      }
    }
  },
  computed: {
    navigationKey: {
      get() {
        return this.$store.getters['navigation/key'];
      },
    },
    passwordConfirmationRule() {
      return [() => (this.passwordNew === this.passwordRepeat) || 'Password must match'];
    },
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const loginpin = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6;
      this.$store.dispatch('dialogloader/show', 'Login ...');

      this.$store.dispatch('user/loginFirstTime', {
        logintoken: this.logintoken,
        passwordOld: this.passwordOld,
        passwordNew: this.passwordNew,
        passwordRepeat: this.passwordRepeat,
        pin: loginpin,
      }).then(() => {
        this.$store.dispatch('navigation/refresh', this.navigationKey + 1);
        this.$router.push('/customeruser');
      })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$router.push({
              name: 'login',
            });
          }
          if (error.response.status === 400) {
            this.$store.dispatch('notificationbar/showNotification', {
              msg: error.response.data.message,
              color: 'error',
              show: true,
            });
            this.mapSymfonyErrors(error.response.data.errors);
            this.resetPin();
          } else {
            this.$router.push('/login');
          }
        })
        .finally(() => {
          this.$store.dispatch('dialogloader/hide');
        });
    },
    onInput(value, fieldNumber) {
      if (value.length === 0) {
        const nextFieldNumber = fieldNumber - 1;
        if (nextFieldNumber >= 1) {
          const nextFieldRef = `pin${nextFieldNumber}`;
          this.$refs[nextFieldRef].focus();
        }
      } else {
        const nextFieldNumber = fieldNumber + 1;
        if (nextFieldNumber <= 6) {
          const nextFieldRef = `pin${nextFieldNumber}`;
          this.$refs[nextFieldRef].focus();
        }
      }
    },
    resetPin() {
      this.pin1 = '';
      this.pin2 = '';
      this.pin3 = '';
      this.pin4 = '';
      this.pin5 = '';
      this.pin6 = '';
    },
  },
};
</script>
<style lang="scss">

</style>
